<template>
  <div>
    <v-chip-group v-if="workouts.length > 0" column>
      <span v-for="(sel, index) in workouts" :key="sel.workoutid + sel.sort">
        <v-chip
          close
          close-icon="mdi-close-circle-outline"
          x-large
          dark
          @click:close="removeWorkout(sel)"
          class="charcoal charcoalTile elevation-3 rounded-lg"
          text-color="progressActive"
          title="Click to change exercise settings (sets, reps, weight, distance, etc)"
        >
          <v-card-text class="pl-0 pt-">
            <span :class="textSizeSmall + ' progressActive--text'">
              {{ prettyfy(index, sel) }}
            </span>
            <v-spacer />
            <span class="paper--text caption">
              {{ sel.type }}
              {{ sel.xmode != null ? ', ' + capitalize(sel.xmode) : '' }}
            </span>
            - {{ sel.num_exercises }} exercises
            <v-spacer />
          </v-card-text>
        </v-chip>
      </span>
    </v-chip-group>
  </div>
</template>
<script>
import { builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  props: {
    type: {
      type: String
    },
    workouts: {
      type: Array
    }
  },
  data() {
    return {
      selectedForEdit: {},
      currentRepscheme: {},
      isSelected: true,
      showEditWorkout: false,
      switchNum: 0,
      loaded: false,
      toMiles: 0.000621371,
      toMeters: 1609.34
    }
  },
  mounted() {
    this.loaded = true
  },
  computed: {
    ...builder
  },
  methods: {
    deleteExercise() {
      this.removeWorkout(this.selectedForEdit)
      this.showEditWorkout = false
    },
    calculateImperial() {
      this.selectedForEdit.distance_imperial = this.fiveDigits(
        this.selectedForEdit.distance_metric * this.toMiles
      )
    },
    calculateMetric() {
      this.selectedForEdit.distance_metric = Math.floor(
        this.selectedForEdit.distance_imperial * this.toMeters
      )
    },
    setRepschemeDetails() {
      var index = -1
      index = this.repSchemes.findIndex(
        p => p.id == this.selectedForEdit.repschemeid
      )
      if (index >= 0) {
        this.currentRepscheme = this.repSchemes[index]
        this.isSelected = true
        this.selectedForEdit.hasRepScheme = 'Yes'
        this.selectedForEdit.sets = this.currentRepscheme.sets
      } else {
        this.isSelected = false
        this.selectedForEdit.hasRepScheme = 'No'
      }
    },
    prettyfy(index, sel) {
      return (
        index +
        1 +
        '. ' +
        sel.name
          .split(' ')
          .slice(0, 6)
          .join(' ')
      )
    },
    removeWorkout(sel) {
      this.$emit('remove', sel)
    },
    editExercise(sel) {
      if (this.type == 'Circuit') return
      this.selectedForEdit = sel
      this.setRepschemeDetails()
      this.showEditWorkout = true
    },
    done(sel) {
      this.$emit('done', sel)
      this.showEditWorkout = false
    }
  }
}
</script>
